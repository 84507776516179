<!-- 这个相当于是那个任务box在这里 -->
<template>
  <div class="add_task_area" id="add_task">
      <div class="closebutton" @click="closebox">
          <close class="closesvg"/>
         
      </div>
      <div class="edit_task_area">
          <div class="task_container">
              <input class="input_text" v-model="taskcontent" type="text" placeholder="请输入你的任务">
          </div>

          <div class="task_property_area">
              <!-- 任务类型选择器 -->
              <div class="select_area">
                  <label for="task_type">任务类型：</label>
                
                  <a-radio-group v-model:value="taskType">
                  <a-radio-button value="normal">普通任务</a-radio-button>
                  <!-- <a-radio-button value="scheduled" v-if="showschedule">
                    <div class="scheduleitem" >
                      <div class="schetab"><div>定时任务💎 </div></div>
                     </div>
                  </a-radio-button> -->
                  <a-radio-button value="scheduled" v-if="showschedule">
    <div class="scheduleitem">
      <div class="schetab">
        <div >定时任务     
      </div>
      <div :class="vip-style">
          <span  class="vip-label">VIP</span>
         
        </div>
      <!-- <div class="vip-badge">
          <svg width="20px" height="20px" viewBox="0 0 1024 1024" class="icon"  version="1.1" xmlns="http://www.w3.org/2000/svg"><path d="M810.666667 917.333333H213.333333c-46.933333 0-85.333333-38.4-85.333333-85.333333V234.666667c0-46.933333 38.4-85.333333 85.333333-85.333334h597.333334c46.933333 0 85.333333 38.4 85.333333 85.333334v597.333333c0 46.933333-38.4 85.333333-85.333333 85.333333z" fill="#880E4F" /><path d="M339.2 597.333333l44.8-194.133333h59.733333l-76.8 268.8h-55.466666L234.666667 403.2h59.733333l44.8 194.133333zM546.133333 672h-53.333333V403.2h53.333333v268.8zM665.6 578.133333v93.866667h-53.333333V403.2H704c78.933333 0 87.466667 72.533333 87.466667 89.6 0 25.6-10.666667 85.333333-87.466667 85.333333h-38.4z m0-46.933333h36.266667c27.733333 0 32-23.466667 32-40.533333 0-34.133333-19.2-44.8-32-44.8h-36.266667v85.333333z" fill="#FFD54F" /></svg>
        </div> -->
        <!-- SVG icon for VIP -->
   
      </div>
    </div>
  </a-radio-button>
                </a-radio-group>


              </div>

              <!-- 普通任务 UI -->
              <div v-if="taskType === 'normal'">
                  <div class="select_area">
                      <timericon/>
                      <a-date-picker 
                          class="selectcomp" 
                          @change="onChangetimer" 
                          :default-value="plantimeMoment" 
                          valueFormat="YYYY-MM-DD" 
                          format="YYYY-MM-DD" 
                          :bordered="false"  
                          placeholder="选择你计划完成的时间(可以不指定)" 
                      />
                  </div>

                  <div class="tags_review_area select_area" @click="clicktag">
                      <tagicon id="tags_button1" class="tags_button" @click="clicktag"/>
                      <a-select  
                          :bordered="false"
                          v-show="true"  
                          class="add_tasktags_area selectcomp"
                          v-model:value="value"
                          mode="multiple"
                          placeholder="选择任务属性(可以不指定)"
                          :max-tag-count="maxTagCount"
                          :options="tasktag_options"
                          @change="handleChange"
                      />
                  </div>

                  <div class="select_area goalselect">
                      <goalicon/>
                      <a-select  
                          :bordered="false"
                          class="selectcomp"
                          v-model:value="selectedgoal"
                          mode="single"
                          placeholder="请选择项目的目标"
                          :max-tag-count="maxTagCount"
                          :options="goal_options"
                          @change="handleChangegoalselect"
                      />
                  </div>

                  <div class="select_area projectselect">
                      <projecticon/>
                      <a-select  
                          class="selectcomp"
                          :bordered="false"
                          v-model:value="selectedproject"
                          mode="single"
                          placeholder="选择对应的项目"
                          :max-tag-count="maxTagCount"
                          :options="project_options"
                          @change="handleChangeprojectselect"
                      />
                  </div>
             
          <div class="add_task_button_area" id="add_task_button_area">
              <!-- 添加任务按钮 -->
              <a-button class="addtasksolobutton" @click="addtasksolo()">添加任务</a-button>
          </div>
                </div>

              <!-- 定时任务 UI -->
         
                <div v-else-if="taskType === 'scheduled'">
  <div class="select_area">
    <input class="input_text" v-model="cronExpression" type="text" placeholder="输入 Cron 表达式">
    <settingicon @click="goToSche"/>
  </div>
  <!-- <div class="select_area goalselect">
    <goalicon/>
    <a-select  
      :bordered="false"
      class="selectcomp"
      v-model:value="selectedgoal"
      mode="single"
      placeholder="请选择项目的目标"
      :max-tag-count="maxTagCount"
      :options="goal_options"
      @change="handleChangegoalselect"
    />
  </div>
  <div class="select_area projectselect">
    <projecticon/>
    <a-select  
      class="selectcomp"
      :bordered="false"
      v-model:value="selectedproject"
      mode="single"
      placeholder="选择对应的项目"
      :max-tag-count="maxTagCount"
      :options="project_options"
      @change="handleChangeprojectselect"
    />
  </div> -->
  <div class="add_task_button_area" id="add_task_button_area">
    <!-- 添加任务按钮 -->
    <a-button class="addtasksolobutton" @click="addscheduletasksolo()">添加定时任务</a-button>
    <!-- 新增跳转按钮 -->
   
    
      
 
  
  </div>
</div>

              </div>


         
      </div>
  </div>
</template>

<script>
import moment from 'moment';
import configure_options from '../configure'
import { defineComponent,ref } from 'vue';
import emitter from "../eventbus.js"; 

import close from '../svg/close.vue'
import goalicon from '../svg/goalicon.vue'
import timericon  from "../svg/timericon.vue"
import tagicon  from "../svg/tagicon.vue"
import projecticon  from "../svg/projecticon.vue"
import dayjs from 'dayjs'
import  settingicon from "../svg/settingicon.vue"
// 加载中文语言包
import 'dayjs/locale/zh-cn'
// import { format } from 'echarts';
const axios = require('axios').default;
export default defineComponent( {
name: 'taskbox',
props: {
msg: String,
plantimePara:String,
goal: {
  type: Object,
  required: true
},
project: {
  type: Object,
  required: true
}
},
components:{
  timericon,
  close,
  goalicon ,
  settingicon,
  tagicon,
  projecticon,
},


mounted(){
// this.options =this.gettasktags()
// this.options =this.getreviewalgo()

this.tasktag_options =this.gettasktags()
this.getgoalincludeprojects()
// this.plantime = dayjs("2023-10-20")
this.getuser()

},

methods:{
  goToSche() {
    this.$router.push('/sche');  // 确保 '/sche' 是你的目标路由
  },
  getgoalincludeprojects(){
        var env =  configure_options["env"]
          console.log("目前的目标是这样的")
          // alert("SHIFOUZHIXING ")
          console.log(Date.now())
          console.log(this.currentgoal)
            console.log(configure_options["app_setting"][env])
            var serverhost =configure_options["app_setting"][env]["serverhost"]
            var _this= this
           axios.get(serverhost+"/v1/goalincludeproject").then(
            response=>{
            // alert("该函数已经被调用")
            var data = response.data
            console.log("~~~~~~~~~~~~~~~goal incluede projects~~~~~~~~~")
            console.log(data);
           
            var goalincludeprojects =data.goalincludeproject
            _this.goalincludeprojects = goalincludeprojects 
            _this.goalnamecodemap = data.goalnamecodemap
            console.log( _this.goalincludeprojects);
            var final_options = []
            // for(var i=0;i<goalincludeprojects.length;i++){
            // final_options.push({id:i,value:goalincludeprojects[i]})
            // }
            var t=0
            for (const key in goalincludeprojects) {
              t=t+1
             final_options.push({id:t,value:key})
              }
            _this.goal_options = final_options
            console.log(_this.goal_options)
       
          }
   ).catch(error=>{console.log(error)})
      },

      handleTaskTypeChange(value) {
            // 处理任务类型选择变化
            this.taskType = value;
            // alert(value)
        },

  handleChangegoalselect(value){
    console.log("对应的project is")
    // alert(`selected ${value}`);
    console.log(value)
    console.log(this.goalincludeprojects)
    console.log(this.project_options)
    var finaloptions = []
    for(var y=0;y<this.goalincludeprojects[value].length;y++){
      finaloptions.push({id:y,value:this.goalincludeprojects[value][y]})
    }
    this.project_options =  finaloptions
    this.selectedgoalcode = this.goalnamecodemap[value]
},
  onChangetimer(date, dateString) {
      console.log(date, dateString);
      // alert(dateString)
      // alert(date)
      // this.plantime = dateString
      this.plantimeString =   dateString
    },

  closebox(){
// this.showboxcontainer = false
  emitter.emit("trunoffsoloaddbox","fromsoloaddbox")
    // alert("1")
  },
onPanelChange(value) {
  console.log(value);
  // alert(this.plantime)
},
},
setup(props,context) {
const value1 = ref(500);
const handleChange = value => {
  console.log(`selected ${value}`);
  context.emit("testchild")
};
// alert(props.plantimePara)





var  options =  [{
              id:0,
              value:"容易的",  
            },{
              id:1,
              value:"麻烦事"
            },{
              id:2,
              value:"体力活"
            },
            {
              id:3,
              value:"3 minutes"
            },
            {
              id:4,
              value:"10 minutes"
            },
            {
              id:5,
              value:"重要的"
            },
            {
              id:6,
              value:"不舒服的"
            },
            
            
            ]

function clicktag(){
  // alert("检测是否被调用")
this.showtagselect = true;
}



function  gettasktags(){
// 

var data =['重要的', '害怕的', '原则', '原子', '需要深度搜索', '困难的', '容易的', '急迫的', '挑战的', '不舒服的', '麻烦事', '关键问题', '10分钟', '30分钟', '3分钟', '人生伟大时刻']

//  []
var final_options = []
for(var i=0;i<data.length;i++){
final_options.push({id:i,value:data[i]})
}


return final_options
}

console.log(dayjs)


function getuser(){
        var env =  configure_options["env"]
   
        console.log(configure_options["app_setting"][env])
        var serverhost =configure_options["app_setting"][env]["serverhost"]
      
        axios.get(serverhost+"/v1/userinfo").then(
          response=>{
            // alert("该函数已经被调用")
            var data = response.data
            var userlevel = data["userlevel"]
            this.serveruserlevel = userlevel
            // alert(userlevel)
          if(userlevel=="vip"){
            this.showschedule = true
          }
       
          }
   ).catch(error=>{console.log(error)})
      }


function  addtasksolo(){
// alert(`父亲任务是${parenttask.task}`)
// alert(this.$store.state.number)
// alert(this.taskcontent)
if(this.plantimeString!="unspecified"){
  if(this.plantimeString.length!=10){
  alert(`日期不符合规范${this.plantimeString.length}`)
  }else{
    var a = moment(this.plantimeString, "YYYY-MM-DD");
    this.plantimeString = a.format("YYMMDD")
  }
}


if(this.taskcontent=="unspecified"||this.taskcontent==""){
  alert("必须指定任务内容")
  return
}

// alert(this.selectedproject)
// alert(goalcode)
// alert(project)
// alert(this.taskcontent)
// alert(this.selectedgoalcode)
// console.log(goalcode,project)
this.showtagselect = false;



//促进一个状态的改变
// var task =  parenttask
// const  scheduleids = [task.ID]
  var tagscovert = {
  "importanttag": "重要的",
  'feartag': "害怕的",
  'principletag': "原则",
  'atomtag': "原子",
  'dfstag': "需要深度搜索",
  'hardtag': "困难的",
  'easytag': "容易的",
  'urgenttag': "急迫的",
  'challengetag': "挑战的",
  'uncomfortabletag': "不舒服的",
  'troublesometag': "麻烦事",
  'keyproblemtag': "关键问题",
  'tenminutestasktag': "10分钟",
  'thirtyminutestasktag': "30分钟",
  'threeminutestasktag': "3分钟",
  'lifetag': "人生伟大时刻",
  // 'recordtag': "创造新的纪录",
}


var tasktags_dict = {
  'importanttag': 'no',
  'feartag': 'no',
  'principletag': 'no',
  'atomtag': 'no',
  'dfstag': 'no',
  'hardtag': 'no',
  'easytag': 'no',
  'urgenttag': 'no',
  'challengetag': 'no',
  'uncomfortabletag': 'no',
  'troublesometag': 'no',
  'keyproblemtag': 'no',
  'tenminutestasktag': 'no',
  'thirtyminutestasktag': 'no',
  'threeminutestasktag': 'no',
  'lifetag': 'no',
  'recordtag':'no'
}
var action = "unspecified"
if(this.selectedgoalcode=="unspecified"){
  if(this.selectedproject=="unspecified" ||this.selectedproject=="不指定任何项目"){
    // alert(111)
    action = "createzerotask"
  }
}else{
  if(this.selectedproject=="unspecified" ||this.selectedproject=="不指定任何项目"){
    // alert(111)
    this.selectedproject = "收集箱"
  }
}
// alert(this.selectedgoalcode,this.selectedproject)

var task = {
      'starttime': 'unspecified',
      'deadline': 'unspecified',
      'endtime': 'unspecified',
      'ifdissect': 'no',
      'note': 'unspecified',
      'parentid': "unspecified",
      'timedevotedto_a_task': "unspecified",
      'goal': 'unspecified',
      'parentproject': 'unspecified',
      'inboxlist': 'empty',
      "client":"gtdcli",
      "action":action,
      // 选中goalcode
      "goalcode":this.selectedgoalcode,
      'inbox': this.taskcontent,
      'finishtime': 'unspecified',
      'reviewalgolight': 'no',
      'reviewalgo': "unspecified",
      'place': 'unspecified',
      'input-time': 'Fri Aug 21 22:38:23 2020',
      'project': this.selectedproject,
      'taskstatus': 'unfinished',
      'plantime': this.plantimeString,
      'taglight': 'yes',
      'tasktags': tasktags_dict,
      // 'client':"dreamforlifeweb",
      'tasktagsorigin': 'e'
    };

// 采用循环来提取对应的tag部分
var task_tags = []
for(var tag in tagscovert){
if(this.value.includes(tagscovert[tag])){
  // task_tags.push(tag)
  tasktags_dict[tag]="yes"
}
}
console.log("选中的任务属性在这里")
console.log(task_tags)
 var env =  configure_options["env"]
   
    console.log(configure_options["app_setting"][env])
    var serverhost =configure_options["app_setting"][env]["serverhost"]

//  const postbody = {"parentid":parenttask.ID,"inbox":"测试任务"}
axios.post(serverhost + '/v1/createtask', task)
.then(response => {
  
    console.log(response.data)
    //可以将状态传入到父亲组件
  //    document.getElementById("addtaskinprojectzone").style.display="none"
  //  document.getElementById("goaltreedatapart").style.opacity=1
  //  document.getElementById("goaltreedatapart").style.pointerEvents="auto"

   emitter.emit("trunoffsoloaddbox","fromsoloaddbox")
  //如果等于today
  let d = new Date();

  let s = d.toJSON().slice(2,10).split`-`.join``;

  let tomorrowstring = moment().add(1, 'days').format("YYMMDD")
  if(this.plantimeString == s){
    emitter.emit("refreshtasks","today") 
  }else if(this.plantimeString == tomorrowstring){
    emitter.emit("refreshtasks","tomorrow") 

  }else{
   console.log("no need to refresh")
  }
    
 
   emitter.emit("refresh_goaltree","createtaskinproject_component_ask")

})
.catch(error=>{console.log(error)})
  return 
}


function addscheduletasksolo(){
  var env = configure_options["env"];
console.log(configure_options["app_setting"][env]);

// 获取服务器主机地址
var serverhost = configure_options["app_setting"][env]["serverhost"];

// 模拟一个请求体数据，可以从前端表单或者其他组件中获取
const scheduletask = {
  "task": this.taskcontent, // 假设 task 是在当前组件的 data 中定义的
  "goalcode": "zero", // 同样假设 goalcode 也是当前组件的 data
  "projectcode": "zero", // 同理假设 projectcode 也是当前组件的 data
  "cron_expression":this.cronExpression,
};
alert(1)
  axios.post(serverhost + '/v1/createsche', scheduletask)
    .then(response => {
      console.log(response.data);

   
    })
    .catch(error => {
      console.error("Error creating task:", error);
    });
}



var plantimeMoment = dayjs(props.plantimePara, 'YYYY-MM-DD' )
var plantimeString = "unspecified"
if (props.plantimePara==undefined){

  plantimeMoment = null
  plantimeString = "unspecified"
}else{
   //将plantime string进行调整
  plantimeString = plantimeMoment.format('YYYY-MM-DD')
}


const taskTypeOptions = ref([
            { label: '普通任务', value: 'normal' },
            { label: '定时任务', value: 'scheduled' }
        ]);

return {
  showtagselect:ref(false),
  handleChange,
  addtasksolo,
  clicktag,
  gettasktags,
  addscheduletasksolo,
  taskTypeOptions,
  tasktag_options:ref([]),
  project_options:ref([]),
  goal_options:ref([]),
  showschedule:ref(false),
  value1,
  options,
  // plantime:ref("unspecified"),
  plantimeMoment,
  plantimeString,
  value: ref([]),
  goalcode:ref("unspecified"),
  goalnamecodemap:ref({}),
  goalincludeprojects:ref({}),
  getuser,
  // editcontent:props.parenttask.task,
  taskcontent:ref(""),
  maxTagCount:ref(2),
  taskchecked: ref(false),
  // selectedgoal:ref("unspecified"),
  // selectedproject:ref("unspecified"),
  selectedgoal:ref("不指定任何目标"),
  selectedproject:ref("不指定任何项目"),
  selectedgoalcode:ref("unspecified"),
  taskType: ref('normal'), // 默认任务类型为普通任务    
  cronExpression: '', // 定时任务的 Cron 表达式
 
};
},




})
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.vip-label {
  color: white;
  background-color: #ff4500; /* 使用鲜艳的橙红色 */
  // border: 1px solid #ff4500;
  // border-radius: 3px;
  padding: 2px 4px;
  font-size: 0.8em;
  margin-left: 5px;
  font-weight: bold; /* 加粗文本 */
}


.add_task_area{



.task_property_area{
  display: flex;
  flex-direction: column;
  // align-items: flex-start;

}


.tags_review_area{
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 70%;
.add_tasktags_area{
  width: 80%;
}
}

.select_area{
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top:15px;
  margin-left:20px;
  .selectcomp{
    width: 80%;
    margin-left: 5px;
    text-align: left;   
  }
}


.edit_task_area{
/* max-width: 30em; */
/* width: 700px; */
width: 100%;
display: flex;
flex-direction: column;
// align-items: flex-start;
/* height: 4em; */
/* margin-left: auto; */
/* margin-right: auto; */
margin-left: 0.2em;
// border: solid 0.1em #ccc;
border-radius: 0.5em;



input::placeholder {
    font-weight: bold;
    opacity: 0.5;
    color: rgb(112, 102, 102);
}

input.input_text{
border: none;
width:90%;

height:1.3cm;
outline:none;
border-bottom : 2px solid rgb(241,243,244);;
transition: border-bottom 2s linear;
&:focus{
    outline: none;
    border-bottom: solid 2px rgb(60, 23, 196);
  }
}




}

.addtasksolobutton { 
// position: absolute;
margin-top:40px;
margin-left: auto;
margin-right: 20px;
border-radius:10px;
color:white;
font-weight: 900;
border:solid 1px;
background: #1a73e8;
// max-width:100px;
&:hover{
background: #4479be;
}
}

.tags_button{
background: none;
border:none
}



}



.add_task_button_area {  display: grid;
grid-template-columns: 1fr;
/* grid-template-rows: 1fr 1fr 1fr 1fr; */
gap: 0px 0px;
grid-auto-flow: row;
grid-template-areas:
"tagzone";
}



.scheduleitem{
  display: flex;
  flex-direction: row;
}

.schetab{
  display: flex;
  flex-direction: row;
}


.closebutton{
  text-align: right;
  // margin-right:10px;
  background: rgb(241,243,244);
  .closesvg{
    margin-right:10px;
  }
}



// .vip-badge {
//   position: absolute; /* Positioning the SVG */
//   top: 0;             /* Align top */
//   right: 0;           /* Align right */
//   transform: translate(50%, -50%); /* Adjust to pull it out to the right top corner */
// }
.scheduleitem {
  position: relative; /* Required to position the SVG absolutely relative to this container */
}
</style>
