<template>

<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	viewBox="0 0 100.353 100.353" width="22px" height="22px"  style="enable-background:new 0 0 100.353 100.353;" xml:space="preserve">
<g>
	<path style="fill:#231F20;" d="M82.245,28.332c1.299-6.524,1.865-14.955-2.815-18.071c-4.679-3.119-12.24,0.647-17.762,4.357
		c-5.877,3.95-12.068,9.706-14.722,13.686l-1.963,2.946c-3.139-0.085-13.09-0.227-16.747,1.266
		c-4.037,1.651-11.394,10.368-11.749,13.92c-0.136,1.365,0.554,2.042,0.979,2.325c0.781,0.519,2.895,0.715,5.645,0.715
		c2.918,0,6.551-0.222,10.07-0.513l-0.663,0.996c-0.001,0.001-0.002,0.002-0.003,0.003s-0.001,0.003-0.002,0.004l-5.587,8.384
		c-3.761,5.653-3.775,13.017-0.138,18.658L24.461,80.5c-0.978,1.466-1.301,3.239-0.908,4.992c0.386,1.728,1.425,3.23,2.926,4.229
		c1.188,0.79,2.527,1.168,3.842,1.168c2.137,0,4.207-1,5.448-2.859l2.319-3.48c0.994,0.178,1.991,0.267,2.983,0.266
		c5.605,0,10.994-2.785,14.195-7.585l6.697-10.053c2.109,6.527,4.629,13.356,6.24,14.43c0.251,0.167,0.665,0.367,1.213,0.367
		c0.377,0,0.817-0.095,1.311-0.362c3.143-1.698,8.35-11.847,8.315-16.205c-0.025-3.947-3.993-13.079-5.281-15.941l1.518-2.279
		C77.932,43.21,80.862,35.28,82.245,28.332z M63.342,17.108c6.533-4.391,11.928-6.017,14.425-4.351
		c2.5,1.665,3.074,7.268,1.536,14.988c-1.491,7.489-4.409,14.611-6.521,17.777L59.189,65.927l-16.05-10.692
		c-0.01-0.007-0.017-0.016-0.027-0.022c-0.01-0.007-0.021-0.01-0.031-0.017l-7.238-4.821l11.139-16.718l0,0l2.459-3.691
		C51.555,26.8,57.007,21.365,63.342,17.108z M19.534,46.363c0.676-2.386,6.502-9.705,9.837-11.07
		c2.399-0.979,9.218-1.125,13.637-1.078l-7.696,11.55C28.879,46.349,21.582,46.691,19.534,46.363z M29.423,60.013l4.757-7.139
		l6.018,4.009L28.636,74.233C26.33,69.769,26.575,64.294,29.423,60.013z M33.273,86.364c-1.089,1.631-3.393,2.016-5.133,0.858
		c-0.854-0.568-1.442-1.416-1.66-2.386c-0.211-0.945-0.042-1.895,0.478-2.673l2.91-4.367c0.008-0.013,0.018-0.025,0.026-0.038
		l12.802-19.213l6.312,4.205L33.273,86.364z M52.77,75.566c-2.852,4.279-7.815,6.613-12.83,6.203l11.564-17.355l6.022,4.012
		L52.77,75.566z M76.041,65.429c0.028,3.594-4.451,11.75-6.404,13.33c-1.164-1.892-3.627-8.684-5.543-14.781l7.691-11.546
		C73.532,56.491,76.025,62.841,76.041,65.429z"/>
	<path style="fill:#231F20;" d="M56.201,44.239c1.767,1.177,3.766,1.741,5.744,1.741c3.36,0,6.662-1.625,8.662-4.625l0.001-0.001
		c3.174-4.77,1.878-11.231-2.89-14.404c-2.309-1.538-5.079-2.086-7.8-1.542s-5.066,2.115-6.603,4.423
		c-1.541,2.311-2.089,5.083-1.543,7.806C52.32,40.358,53.893,42.703,56.201,44.239z M55.814,31.494
		c1.092-1.641,2.76-2.758,4.694-3.145c0.487-0.098,0.978-0.146,1.464-0.146c1.443,0,2.855,0.424,4.085,1.243
		c3.39,2.256,4.312,6.852,2.054,10.244c-2.259,3.39-6.857,4.311-10.246,2.051c-1.643-1.092-2.762-2.76-3.149-4.695
		C54.327,35.111,54.717,33.139,55.814,31.494z"/>
</g>
</svg>
       </template>
       
       <script>
       export default {
          name: 'projecticon',
       }
       </script>
       
       <style scoped>
       </style>